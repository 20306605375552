input,
textarea {
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.sectionProd,
.sectionProd2 {
  justify-content: center;
  margin: 0 auto;
  display: flex;
}
.App,
.sectionFoods {
  animation: 0.5s fadeIn;
}
.glow,
.slogan {
  cursor: pointer;
}
.App {
  user-select: none;
  text-align: center;
  margin: 0 auto;
}
.sectionProd {
  align-items: center;
  flex-wrap: wrap;
}
.sectionProd2 {
  align-items: center;
  flex-wrap: wrap-reverse;
}
.containerProd {
  margin-top: 2%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.containerProdText {
  width: 1000px;
  padding: 32px;
}
.slogan,
.sloganFont {
  width: 100%;
  white-space: nowrap;
}
.marginMinus,
.marginMinusProd {
  justify-content: center;
  text-align: center;
  display: flex;
}
.sectionFoods {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.sloganFont {
  font-family: Pacifico, 'Great Vibes';
  line-height: 1.2;
  color: var(--color-red, red);
  display: inline-block;
}
.slogan {
  /* flex: 1;
  overflow: hidden; */
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 1600px) {
  .span,
  nav {
    position: fixed;
  }
  .sun,
  .topImg:hover {
    cursor: pointer;
  }
  .center,
  .toTop,
  .top,
  nav {
    width: 100%;
  }
  .active,
  .nextBtn:hover,
  .preBtn:hover,
  .toTop:hover {
    color: var(--color-red);
  }
  a,
  textarea {
    font-size: 18px;
  }
  .active,
  a,
  a:hover {
    text-decoration: none;
  }

  .imgBlock,
  .searchBox,
  .shadowImgFoods,
  button {
    box-shadow: var(--color-shadow);
  }
  .formInput:focus,
  input,
  input:focus {
    outline: 0;
  }
  .animationRotate:hover,
  .menuButton,
  button {
    transition: 0.1s ease-in-out;
  }
  nav {
    top: 4px;
    opacity: 100%;
    animation: 0.5s fadeIn;
    z-index: 9998;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sloganBox,
  .sloganBox2 {
    display: flex;
    justify-content: center;
  }
  textarea {
    font-weight: 400;
  }
  .active,
  h1 {
    font-weight: 700;
  }
  .st0 {
    fill: var(--color-black-logo, black);
  }
  .st1 {
    fill: var(--color-red, red);
  }
  .st2 {
    z-index: 8999;
    fill: white;
  }
  .searchImg,
  .span,
  .sun {
    z-index: 9999;
    right: 16px;
  }
  .st9 {
    fill: var(--color-black-sun);
  }
  .st7 {
    fill: var(--color-white-sun);
  }
  .span {
    top: -30px;
  }
  .prapor,
  .sun {
    height: 26px;
    top: 7px;
    position: fixed;
    animation: 0.5s fadeIn;
  }
  .sun {
    border-radius: 100%;
  }
  .sloganBox {
    margin: 16px 2px;
    align-items: center;
  }
  .sloganBox2 {
    align-items: center;
    border-top: 2px solid var(--color-red-dark);
    border-radius: 5px;
    max-width: 500px;
  }
  .sloganLogo,
  .toTop {
    display: flex;
    justify-content: center;
  }
  .sloganLogo {
    align-items: center;
    padding: 8px 0;
  }
  .slogan {
    margin-left: -25px;
  }
  .toTop {
    margin: 20px auto;
  }
  .logo,
  .top {
    justify-content: center;
  }
  .center {
    margin: 0 auto;
  }
  .prapor {
    border-radius: 100%;
    left: 16px;
  }
  .menuImg {
    animation: 0.5s fadeIn;
    height: 52px;
    position: fixed;
    right: 16px;
    bottom: 25px;
  }
  .searchImg {
    animation: 0.5s fadeIn;
    height: 43px;
    position: fixed;
    bottom: 90px;
  }
  .topImg {
    animation: 0.5s fadeIn;
    margin: 0;
    height: 20px;
    position: fixed;
    bottom: 7px;
  }
  .animationRotate,
  .logo {
    height: auto;
  }
  .top {
    opacity: 0;
    padding-top: 52px;
    margin: 0 auto;
    display: flex;
  }
  .containerProd,
  .containerProdText,
  .logo {
    width: 500px;
    display: flex;
  }
  .sectionFoods {
    margin: 10px auto;
    max-width: 1200px;
  }
  .containerFood {
    margin: 0 16px;
  }
  .logo {
    animation: 0.5s fadeIn;
    margin: 10px;
    min-width: 300px;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .sectionProd,
  .sectionProd2 {
    animation: 0.5s fadeIn;
    padding: 0 12px;
    max-width: 1200px;
  }

  .containerProdText {
    justify-content: left;
    text-align: left;
    flex-wrap: wrap;
    white-space: pre-line;
    padding: 16px;
  }
  .animationRotate:hover {
    scale: 102%;
    animation-name: rotate;
    animation-duration: 0.5s;
  }
  .marginMinus {
    margin: -4px auto 30px;
    max-width: 160px;
  }
  .marginMinusProd {
    margin: -39px auto 30px;
    max-width: 160px;
  }
  a {
    color: var(--color-text);
  }
  .centerImg {
    display: block;
  }
  .shadowImgFoods {
    width: 200px;
    height: 200px;
    border: 1.5px solid var(--color-white);
    border-radius: 100%;
  }
  .shadowImgFoods:hover {
    border: 1px solid var(--color-red-dark);
  }
  .img,
  .imgBlock,
  button {
    border-radius: 5px;
  }
  .shadowImg {
    width: 300px;
    height: 200px;
  }
  .img {
    animation: 0.5s fadeIn;
    width: 100%;
  }
  .imgBox {
    width: 100%;
    max-width: 500px;
    margin: 12px;
  }
  .imgBlock {
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .text {
    animation: 0.5s fadeIn;
    display: flex;
    justify-content: center;
    padding: 12px;
  }
  .texts {
    animation: 0.5s fadeIn;
    display: inline-block;
    max-width: 100%;
    padding: 0 8px 32px;
  }
  .paddingText1 {
    width: 100%;
    padding: 4px 8px;
  }
  .paddingText2 {
    padding: 0 8px 16px;
  }
  h1 {
    font-size: 30px;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.5s;
  }
  @keyframes animation-buzz {
    50% {
      transform: translateX(3px) rotate(2deg);
    }
    100% {
      transform: translateX(-3px) rotate(-2deg);
    }
  }
  .animation-buzz:active,
  .animation-buzz:focus,
  .animation-buzz:hover {
    animation-name: animation-buzz;
    animation-duration: 0.15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes animation-buzz-out {
    10%,
    30% {
      transform: translateX(3px) rotate(2deg);
    }
    20%,
    40% {
      transform: translateX(-3px) rotate(-2deg);
    }
    50%,
    70% {
      transform: translateX(2px) rotate(1deg);
    }
    60%,
    80% {
      transform: translateX(-2px) rotate(-1deg);
    }
    90% {
      transform: translateX(1px) rotate(0);
    }
    100% {
      transform: translateX(-1px) rotate(0);
    }
  }
  .animation-buzz-out:active,
  .animation-buzz-out:focus,
  .animation-buzz-out:hover {
    animation-name: animation-buzz-out;
    animation-duration: 0.75s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }
  @keyframes rotate {
    0%,
    100% {
      transform: rotate(0);
    }
    20% {
      transform: rotate(1deg);
    }
    50% {
      transform: rotate(-0.5deg);
    }
    84% {
      transform: rotate(0.3deg);
    }
  }
  button {
    font-weight: 600;
    animation: 0.5s fadeIn;
    padding: 12px 20px;
    margin: 16px;
    font-size: 16px;
    border: 0;
    cursor: pointer;
    background-color: var(--color-grey);
    color: var(--color-text);
  }
  .nextBtn,
  .preBtn {
    cursor: pointer;
    z-index: 9993;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    border-radius: 100%;
    color: var(--color-text);
  }
  .nextBtn {
    align-items: center;
    right: 0.01%;
  }
  .preBtn {
    align-items: center;
    left: 0.01%;
  }
  .inputBox,
  .sectionContact {
    display: flex;
    justify-content: center;
  }
  .inputBox,
  .searchBox {
    z-index: 9999;
    border-radius: 5px;
    animation: 0.5s fadeIn;
    position: fixed;
  }
  .inputBox,
  input:focus {
    background-color: var(--color-body);
    color: var(--color-red);
  }
  .sectionContact {
    animation: 0.5s fadeIn;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 500px;
  }
  .inputBox {
    border-color: var(--color-red);
    width: 90%;
    margin: 0;
    padding: 12px;
    font-size: 21px;
    font-weight: 500;
    max-width: 300px;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  input {
    font-weight: 400;
    border-style: hidden;
    border-bottom: 2px solid var(--color-red-dark);
  }
  .formInput,
  .formInput:focus {
    background-color: var(--color-body);
    color: var(--color-text);
  }
  input::placeholder {
    color: var(--color-text);
    opacity: 0.2;
  }
  input:focus::placeholder {
    color: var(--color-text);
    opacity: 0.1;
  }
  input:hover::placeholder {
    opacity: 0.1;
  }
  .searchBox {
    background-color: var(--color-body);
    right: 70px;
    bottom: -12px;
    max-width: 430px;
    padding: 4px;
    height: 70px;
    text-align: center;
  }
  .form {
    display: inline-block;
    background: var(--color-body);
    width: 100%;
  }
  .formText {
    margin: 20px auto;
  }
  .formInputContainer {
    width: 100%;
    margin: 8px auto;
  }
  .formInput {
    width: 90%;
    display: flex;
    margin: 16px auto;
    padding: 12px;
    font-size: 16px;
    border: 1px solid;
    border-color: var(--color-text);
    border-radius: 5px;
  }
  .formInput::placeholder {
    color: var(--color-text);
    opacity: 1;
  }
  .formInput:focus::placeholder {
    color: var(--color-text);
    opacity: 0.1;
  }
  .formInput:hover::placeholder {
    opacity: 0.1;
  }
  .errorMessage {
    width: 100%;
    color: var(--color-red);
    font-size: 14px;
    margin-top: -14px;
  }
  .blockMenuEnd,
  .blockSearchMenu {
    width: 90%;
    background-color: var(--color-dark);
    color: var(--color-text);
    display: flex;
  }
  .containerMenuEnd,
  .containerMenuEnd2 {
    padding-bottom: 20px;
    max-width: 300px;
    margin: 0 auto;
  }
  .containerMenuEnd {
    padding-top: 40px;
  }
  .containerMenuEnd2 {
    padding-top: 0;
  }
  .blockMenuEnd,
  .blockSearchMenu,
  .menuButton {
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: var(--color-shadow);
  }
  .blockMenuEnd {
    z-index: 800;
    margin: 4px auto;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .blockSearchMenu {
    justify-content: left;
    align-items: center;
    margin: 2px auto;
  }
  .blockSearchMenu:hover {
    background-color: var(--color-grey);
    scale: 102%;
  }
  .imgSearchFood {
    height: 50px;
    animation: 0.5s fadeIn;
  }
  .containerMenuFood {
    margin-top: -2px;
  }
  .blockMenuFood {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 2px auto;
    width: 80%;
    background-color: var(--color-body);
    box-shadow: var(--color-shadow);
    border-radius: 5px;
    color: var(--color-light);
    cursor: pointer;
  }
  .rowMenuFood {
    text-align: left;
    animation: 0.5s fadeIn;
    margin: 2px 4px;
  }
  .imgMenuFood {
    height: 50px;
    width: 50px;
    animation: 0.5s fadeIn;
    margin: 2px 8px;
  }
  .menuButton {
    width: 111px;
    height: 22px;
    margin: 1px 4px;
    font-weight: 600;
    animation: 0.5s fadeIn;
    font-size: 16px;
    border: 0;
    background-color: var(--color-grey);
  }
  .width120 {
    margin-top: 20px;
    width: 120px;
  }
  h2 {
    font-size: 27px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  .invisible {
    position: fixed;
    bottom: -30px;
    color: rgba(111, 111, 111, 0);
    font-size: 1px;
  }
  .lineUp {
    margin: 1%;
    padding: 1% 2%;
    border-radius: 5px;
    display: inline-block;
    border-top: 2px solid var(--color-red-dark);
  }
  .lineDown {
    border-bottom: 1px solid var(--color-red-dark);
  }
  .lineDownW {
    border-bottom: 1px solid var(--color-light);
  }
}
@media (min-width: 1600px) {
  .sun,
  nav {
    animation: 0.5s fadeIn;
    position: fixed;
  }
  .span,
  .sun,
  nav {
    position: fixed;
  }
  .sun,
  .topImg:hover {
    cursor: pointer;
  }
  .center,
  .toTop,
  .top,
  nav {
    width: 100%;
  }
  .active,
  .nextBtn:hover,
  .preBtn:hover,
  .toTop:hover {
    color: var(--color-red);
  }
  a,
  textarea {
    font-size: 36px;
  }
  .active,
  a,
  a:hover {
    text-decoration: none;
  }

  .imgBlock,
  .logo-NR,
  .searchBox,
  .shadowImgFoods,
  button {
    box-shadow: var(--color-shadow);
  }
  .formInput:focus,
  input,
  input:focus {
    outline: 0;
  }
  .animationRotate:hover,
  .menuButton,
  button {
    transition: 0.1s ease-in-out;
  }
  .lineDown {
    border-bottom: 2px solid var(--color-red-dark);
  }
  .lineDownW {
    border-bottom: 2px solid var(--color-light);
  }
  nav {
    top: 8px;
    opacity: 100%;
    z-index: 9998;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sloganBox,
  .sloganBox2 {
    display: flex;
    justify-content: center;
  }
  textarea {
    font-weight: 400;
  }
  .active,
  h1 {
    font-weight: 700;
  }
  .st0 {
    fill: var(--color-black-logo, black);
  }
  .st1 {
    fill: var(--color-red, red);
  }
  .st2 {
    z-index: 8999;
    fill: white;
  }
  .searchImg,
  .span,
  .sun {
    z-index: 9999;
  }
  .st9 {
    fill: var(--color-black-sun);
  }
  .st7 {
    fill: var(--color-white-sun);
  }
  .span {
    right: 16px;
    top: -30px;
  }
  .prapor,
  .sun {
    height: 52px;
    top: 14px;
  }
  .sun {
    border-radius: 100%;
    right: 32px;
  }
  .menuImg,
  .searchImg {
    right: 16px;
    position: fixed;
    animation: 0.5s fadeIn;
  }
  .sloganBox {
    margin: 32px 4px;
    align-items: center;
  }
  .sloganBox2 {
    align-items: center;
    border-top: 4px solid var(--color-red-dark);
    border-radius: 10px;
    max-width: 1000px;
  }
  .sloganLogo,
  .toTop {
    display: flex;
    justify-content: center;
  }
  .sloganLogo {
    align-items: center;
    padding: 16px 0;
  }
  .slogan {
    margin-left: -50px;
  }
  .toTop {
    margin: 20px auto;
  }
  .logo,
  .top {
    justify-content: center;
  }
  .center {
    margin: 0 auto;
  }
  .prapor {
    animation: 0.5s fadeIn;
    position: fixed;
    border-radius: 100%;
    left: 32px;
  }
  .menuImg {
    height: 104px;
    bottom: 50px;
  }
  .searchImg {
    height: 86px;
    bottom: 180px;
  }
  .topImg {
    animation: 0.5s fadeIn;
    margin: 0;
    height: 40px;
    position: fixed;
    bottom: 7px;
  }
  .top {
    opacity: 0;
    padding-top: 104px;
    margin: 0 auto;
    display: flex;
  }
  .containerProd,
  .containerProdText,
  .logo {
    width: 1000px;
    display: flex;
  }
  .sectionFoods {
    margin: 20px auto;
    max-width: 2400px;
  }
  .containerFood {
    margin: 0 32px;
  }
  .logo {
    animation: 0.5s fadeIn;
    margin: 20px;
    min-width: 600px;
    height: auto;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .sectionProd,
  .sectionProd2 {
    animation: 0.5s fadeIn;
    padding: 0 24px;
    max-width: 2400px;
  }

  .containerProdText {
    justify-content: left;
    text-align: left;
    flex-wrap: wrap;
    white-space: pre-line;
    padding: 32px;
  }
  .animationRotate:hover {
    scale: 102%;
    animation-name: rotate;
    animation-duration: 0.5s;
  }
  .marginMinus {
    margin: -8px auto 60px;
    max-width: 320px;
  }
  .marginMinusProd {
    margin: -63px auto 60px;
    max-width: 320px;
  }
  a {
    color: var(--color-text);
  }
  .centerImg {
    display: block;
  }
  .shadowImgFoods {
    width: 400px;
    height: 400px;
    border: 2px solid var(--color-white);
    border-radius: 100%;
  }
  .shadowImgFoods:hover {
    border: 2px solid var(--color-red-dark);
  }
  .logo-NR {
    border-radius: 2%;
  }
  .img,
  .imgBlock,
  button {
    border-radius: 10px;
  }
  .shadowImg {
    width: 600px;
    height: 400px;
  }
  .img {
    animation: 0.5s fadeIn;
    width: 100%;
  }
  .imgBox {
    width: 100%;
    max-width: 1000px;
    margin: 24px;
  }
  .imgBlock {
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .text {
    animation: 0.5s fadeIn;
    display: flex;
    justify-content: center;
    padding: 24px;
  }
  .texts {
    animation: 0.5s fadeIn;
    display: inline-block;
    max-width: 100%;
    padding: 0 16px 64px;
  }
  .paddingText1 {
    width: 100%;
    padding: 8px 16px;
  }
  .paddingText2 {
    padding: 0 16px 32px;
  }
  h1 {
    font-size: 60px;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.5s;
  }
  @keyframes animation-buzz {
    50% {
      transform: translateX(3px) rotate(2deg);
    }
    100% {
      transform: translateX(-3px) rotate(-2deg);
    }
  }
  .animation-buzz:active,
  .animation-buzz:focus,
  .animation-buzz:hover {
    animation-name: animation-buzz;
    animation-duration: 0.15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes animation-buzz-out {
    10%,
    30% {
      transform: translateX(3px) rotate(2deg);
    }
    20%,
    40% {
      transform: translateX(-3px) rotate(-2deg);
    }
    50%,
    70% {
      transform: translateX(2px) rotate(1deg);
    }
    60%,
    80% {
      transform: translateX(-2px) rotate(-1deg);
    }
    90% {
      transform: translateX(1px) rotate(0);
    }
    100% {
      transform: translateX(-1px) rotate(0);
    }
  }
  .animation-buzz-out:active,
  .animation-buzz-out:focus,
  .animation-buzz-out:hover {
    animation-name: animation-buzz-out;
    animation-duration: 0.75s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }
  @keyframes rotate {
    0%,
    100% {
      transform: rotate(0);
    }
    20% {
      transform: rotate(1deg);
    }
    50% {
      transform: rotate(-0.5deg);
    }
    84% {
      transform: rotate(0.3deg);
    }
  }
  button {
    font-weight: 600;
    animation: 0.5s fadeIn;
    padding: 20px 40px;
    margin: 32px;
    font-size: 32px;
    border: 0;
    cursor: pointer;
    background-color: var(--color-grey);
    color: var(--color-text);
  }
  .nextBtn,
  .preBtn {
    cursor: pointer;
    z-index: 9993;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    border-radius: 100%;
    color: var(--color-text);
  }
  .nextBtn {
    align-items: center;
    right: 0.01%;
  }
  .preBtn {
    align-items: center;
    left: 0.01%;
  }
  .inputBox,
  .sectionContact {
    display: flex;
    justify-content: center;
  }
  .inputBox,
  .searchBox {
    z-index: 9999;
    border-radius: 10px;
    animation: 0.5s fadeIn;
    position: fixed;
  }
  .inputBox,
  input:focus {
    background-color: var(--color-body);
    color: var(--color-red);
  }
  .sectionContact {
    animation: 0.5s fadeIn;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1000px;
  }
  .inputBox {
    border-color: var(--color-red);
    width: 90%;
    margin: 0;
    padding: 24px;
    font-size: 42px;
    font-weight: 500;
    max-width: 600px;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  input {
    font-weight: 400;
    border-style: hidden;
    border-bottom: 4px solid var(--color-red-dark);
  }
  .formInput,
  .formInput:focus {
    background-color: var(--color-body);
    color: var(--color-text);
  }
  input::placeholder {
    color: var(--color-text);
    opacity: 0.2;
  }
  input:focus::placeholder {
    color: var(--color-text);
    opacity: 0.1;
  }
  input:hover::placeholder {
    opacity: 0.1;
  }
  .searchBox {
    background-color: var(--color-body);
    right: 140px;
    bottom: -12px;
    max-width: 860px;
    padding: 8px;
    height: 140px;
    text-align: center;
  }
  .form {
    display: inline-block;
    background: var(--color-body);
    width: 100%;
  }
  .formText {
    margin: 40px auto;
  }
  .formInputContainer {
    width: 100%;
    margin: 16px auto;
  }
  .formInput {
    width: 90%;
    display: flex;
    margin: 32px auto;
    padding: 24px;
    font-size: 32px;
    border: 1px solid;
    border-color: var(--color-text);
    border-radius: 10px;
  }
  .formInput::placeholder {
    color: var(--color-text);
    opacity: 1;
  }
  .formInput:focus::placeholder {
    color: var(--color-text);
    opacity: 0.1;
  }
  .formInput:hover::placeholder {
    opacity: 0.1;
  }
  .errorMessage {
    width: 100%;
    color: var(--color-red);
    font-size: 28px;
    margin-top: -28px;
  }
  .containerMenuEnd,
  .containerMenuEnd2 {
    padding-bottom: 40px;
    max-width: 600px;
    margin: 0 auto;
  }
  .containerMenuEnd {
    padding-top: 80px;
  }
  .containerMenuEnd2 {
    padding-top: 0;
  }
  .blockMenuEnd,
  .blockSearchMenu {
    padding: 16px;
    width: 90%;
    background-color: var(--color-dark);
    color: var(--color-text);
    display: flex;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: var(--color-shadow);
  }
  .blockMenuEnd {
    z-index: 800;
    margin: 8px auto;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .blockSearchMenu {
    justify-content: left;
    align-items: center;
    margin: 4px auto;
  }
  .blockSearchMenu:hover {
    background-color: var(--color-grey);
    scale: 102%;
  }
  .imgSearchFood {
    height: 100px;
    animation: 0.5s fadeIn;
  }
  .containerMenuFood {
    margin-top: -4px;
  }
  .blockMenuFood {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 4px auto;
    width: 80%;
    background-color: var(--color-body);
    box-shadow: var(--color-shadow);
    border-radius: 10px;
    color: var(--color-light);
    cursor: pointer;
  }
  .rowMenuFood {
    text-align: left;
    animation: 0.5s fadeIn;
    margin: 4px 8px;
  }
  .imgMenuFood {
    height: 100px;
    width: 100px;
    animation: 0.5s fadeIn;
    margin: 4px 16px;
  }
  .menuButton {
    width: 222px;
    height: 44px;
    padding: 18px;
    margin: 2px 8px;
    font-weight: 600;
    animation: 0.5s fadeIn;
    font-size: 32px;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
    background-color: var(--color-grey);
    box-shadow: var(--color-shadow);
  }
  .invisible,
  .invisible h4 {
    font-size: 1px;
  }
  .width120 {
    margin-top: 40px;
    width: 240px;
  }
  h2 {
    font-size: 54px;
  }
  h3 {
    font-size: 36px;
  }
  h4 {
    font-size: 32px;
  }
  .invisible {
    z-index: 1;
    position: fixed;
    bottom: -30000px;
    color: rgba(111, 111, 111, 0);
  }
  .lineUp {
    margin: 1%;
    padding: 1% 2%;
    border-radius: 10px;
    display: inline-block;
    border-top: 4px solid var(--color-red-dark);
  }
}
@media (max-width: 570px) {
  nav {
    top: -100%;
  }
  .prapor {
    border-radius: 100%;
    height: 20px;
    left: 10px;
    top: 10px;
  }
  /* .slogan {
    margin-left: -50px;
  } */
  h2 {
    font-size: 18px;
  }
  .sectionFoods {
    margin: 5px auto;
    max-width: 400px;
  }
  .containerFood {
    margin: 0 8px;
  }
  .shadowImgFoods {
    width: 100px;
    height: 100px;
    border: 1px solid var(--color-white);
    border-radius: 100%;
    box-shadow: var(--color-shadow);
  }
  .shadowImgFoods:hover {
    border: 1px solid var(--color-red-dark);
  }
  .shadowImg {
    width: 250px;
    height: 180px;
  }
}
@media (max-width: 400px) {
  h2 {
    font-size: 14px;
  }
}
@keyframes flagAnimation {
  0% {
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-radius: 100%;
    transform: scale(0);
    opacity: 0;
  }
  7% {
    border-radius: 1%;
    opacity: 1;
  }
  50% {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1);
  }
  100% {
    border-radius: 1%;
    transform: scale(1);
    opacity: 0;
  }
}
.flagAnimation {
  animation: 3s forwards flagAnimation;
  position: fixed;
  background: linear-gradient(#0057b8 50%, gold 50%);
}
.lineUnderText {
  margin: 1%;
  padding: 1% 2%;
  border: 2px solid var(--color-red-dark);
  border-radius: 4px;
  display: inline-block;
  border-style: hidden hidden groove;
  outline: 0;
  color: var(--color-light);
}
.glow,
.glow-on-hover:active,
.marginMinusCol:hover,
.marginMinusProdCol:hover {
  color: var(--color-red);
}
.glow-on-hover {
  animation: 0.5s fadeIn;
}
.glow-on-hover:before {
  content: '';
  background: linear-gradient(
    45deg,
    var(--color-red-dark),
    #ff391e,
    var(--color-red-dark)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: 20s linear infinite glowing;
  opacity: 0;
}
.glow-on-hover:hover:before {
  opacity: 1;
}
.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-body);
  left: 0;
  top: 0;
  border-radius: 7px;
}
@keyframes glowing {
  0%,
  100% {
    background-position: 0 0;
  }
  50% {
    background-position: 500% 0;
  }
}
@keyframes glowing1 {
  0%,
  100% {
    box-shadow: 0 0 5px red, 0 0 25px red, 0 0 50px red, 0 0 200px red;
  }
  50% {
    box-shadow: 0 0 10px #ff7f00, 0 0 20px #ff7f00, 0 0 40px #ff7f00,
      0 0 150px #ff7f00;
  }
}
@keyframes glowing2 {
  0%,
  100% {
    box-shadow: 0 0 5px red, 0 0 25px red;
    shadow-position: 0% 0;
  }
  50% {
    box-shadow: 0 0 10px #ff7f00, 0 0 20px #ff7f00;
    shadow-position: 300% 0;
  }
}
.glow {
  font-size: 1.4em;
  text-align: center;
  padding: 12px 24px;
  background-color: var(--color-body);
  border-radius: 7px;
  position: relative;
  margin: 0 auto;
  display: block;
  width: 200px;
  animation: 2s infinite glowing2;
}
@keyframes colorAnimation {
  0%,
  100% {
    color: #b40000;
  }
  50% {
    color: #ff3434;
  }
}
.colorChange {
  animation: 7s infinite colorAnimation;
}
@keyframes scale102 {
  0% {
    scale: 100%;
  }
  100% {
    scale: 102%;
  }
}
@keyframes scaleAnimation {
  0%,
  100% {
    rotate: 0;
    scale: 97%;
  }
  25% {
    rotate: 0.5deg;
  }
  50% {
    scale: 100%;
  }
  75% {
    rotate: -0.5deg;
  }
}
@keyframes scaleAnimation2 {
  0%,
  100% {
    rotate: 0;
    scale: 97%;
  }
  25% {
    rotate: -0.5deg;
  }
  50% {
    scale: 100%;
  }
  75% {
    rotate: 0.5deg;
  }
}
@keyframes scaleAnimation3 {
  0%,
  100% {
    transform: scale(0.97);
  }
  50% {
    transform: scale(1);
  }
}
.scaleChange {
  animation: 7s infinite scaleAnimation;
}
.scaleChange2 {
  animation: 9s infinite scaleAnimation2;
}
.scaleChange3 {
  animation: 5s infinite scaleAnimation3;
}
.menuButton:hover,
button:hover {
  background: linear-gradient(
    to bottom,
    var(--color-red),
    var(--color-red-dark)
  );
  color: var(--color-white);
  transition: 0.1s ease-in-out;
  scale: 102%;
}
.blockMenuEnd:hover,
.blockMenuFood:hover {
  background-color: var(--color-grey);
  color: var(--color-red);
}
.menuImg:hover,
.searchImg:hover,
.sun:hover {
  transition: 0.1s ease-in-out;
  scale: 106%;
}
.scale:hover,
.slogan:hover {
  transition: 0.1s ease-in-out;
  scale: 102%;
}
.blockMenuEnd:hover,
.blockMenuFood:hover {
  scale: 102%;
}
.sloganBox .left-part {
  width: 30%;
}
.sloganBox .right-part {
  width: 70%;
}
.active {
  background-color: var(--color-body);
  border-bottom: 1px solid var(--color-red-dark);
  box-shadow: 0px 2px 5px rgba(120, 0, 0, 0.7);
}
.noActive {
  border-bottom: 0;
  box-shadow: 0 0 0 0;
  background: #00000000;
}
.containerProd2 {
  display: flex;
  justify-content: center;
  text-align: center;
}
.containerProd90 {
  width: 90%;
}
