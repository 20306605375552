* {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

:root[data-theme="light"] {
  --color-body: rgb(223, 223, 223);
  --color-nav: rgba(232, 232, 232, 0.9);
  --color-red: rgb(210, 0, 0);
  --color-red-dark: rgb(180, 0, 0);
  --color-white: rgb(232, 232, 232);
  --color-light: rgb(160, 160, 160);
  --color-grey: rgb(210, 210, 210);
  --color-dark: rgb(223, 223, 223);
  --color-shadow: 0 2px 3px 0 rgb(120, 120, 120);
  --color-text: rgb(52, 52, 52);
  --color-black-logo: rgb(34, 34, 34);
  --color-white-sun: rgba(210, 210, 210, 0);
  --color-black-sun: rgb(52, 52, 52);
}

:root[data-theme="dark"] {
  --color-body: rgb(43, 43, 43);
  --color-nav: rgba(52, 52, 52, 0.9);
  --color-red: rgb(255, 0, 0);
  --color-red-dark: rgb(180, 0, 0);
  --color-white: rgb(210, 210, 210);
  --color-light: rgb(120, 120, 120);
  --color-grey: rgb(70, 70, 70);
  --color-dark: rgb(57, 57, 57);
  --color-shadow: 0 2px 3px 0 rgb(30, 30, 30);
  --color-text: rgb(210, 210, 210);
  --color-black-logo: rgba(43, 43, 43, 0);
  --color-white-sun: rgb(210, 210, 210);
  --color-black-sun: rgba(52, 52, 52, 0);
}

body {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: var(--color-text);
  background-color: var(--color-body);
}
